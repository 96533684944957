/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          
          
          // skip to content
           // bind a click event to the 'skip' link
          $(".skip").click(function(event){

            // strip the leading hash and declare
            // the content we're skipping to
            var skipTo="#"+this.href.split('#')[1];

            // Setting 'tabindex' to -1 takes an element out of normal 
            // tab flow but allows it to be focused via javascript
            $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

              // when focus leaves this element, 
              // remove the tabindex attribute
              $(this).removeAttr('tabindex');

            }).focus(); // focus on the content container
          });
          
          
          // hamburger menu animation
          document.querySelector( ".navbar-default .navbar-toggle" )
              .addEventListener( "click", function() {
                document.querySelector("#nav-toggle").classList.toggle( "active" );
                document.querySelector(".navbar-default .navbar-toggle .mobile-only").classList.toggle( "active" );
                    
                    var menuText = document.querySelectorAll(".navbar-default .navbar-toggle .mobile-only")[0];
                      if (menuText.innerHTML === "MENU") {
                        menuText.innerHTML = "CLOSE";
                      } else {
                        menuText.innerHTML = "MENU";
                      }
              
              });
          
          
          /* move footerLinks to headerTop above 900px */
          //var width = $(window).width();
          // get true width and height vars
          var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
          var width = x;
          			
            if (width >= 899) {
				var footerLinks = $('.footerLinks').detach();
                $('header.banner').before(footerLinks);
            }
          
          
            /* responsive logo */
            if (width >= 600) {
                $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/utmain-news/dist/images/ut-system-news.png');
            }
          
            var searchForm = $('header .widget').detach();
            
            if (width <= 600) {
                $('.navbar-brand').before(searchForm);
            }
            else
            {
                $('.navbar-header').after(searchForm);
            }
            
            var utNav = $('#utNav').detach();
            
            if (width <= 600) {
                $('#catNav').after(utNav);
            }
            else
            {
                $('header section.widget').after(utNav);
            }
          
          
            
          
          /* responsive */
          
          $(window).resize(function(){

					// get true width and height vars
                    var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
                    var width = x;
					
					var height = $(window).height();
					
					/* manage header nav location */
			         var footerLinks = $('.footerLinks').detach();
              
				    if (width >= 899) {
						$('header.banner').before(footerLinks);
				    }
				    else
					{
						/* put back where it goes */
				        $('footer.content-info').after(footerLinks);
					}
              
              
                    /* responsive logo */
                    if (width >= 600) {
						$('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/utmain-news/dist/images/ut-system-news.png');
				    }
				    else
					{
						/* put back where it goes */
				        $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/utmain-news/dist/images/ut-mobile.png');
					}
              
                    var searchForm = $('header .widget').detach();
            
                    if (width <= 600) {
                        $('.navbar-brand').before(searchForm);
                    }
                    else
                    {
                        $('.navbar-header').after(searchForm);
                    }
              
                    var utNav = $('#utNav').detach();
            
                    if (width <= 600) {
                        $('#catNav').after(utNav);
                    }
                    else
                    {
                        $('header section.widget').after(utNav);
                    }
                
          });
          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
